import { graphql } from 'gatsby';
import React from 'react';
import CtaButton from 'components/atoms/CtaButton';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';

const GetStartedContent = ({ convenienceRetail, enterpriseBrand }) => {
  return (
    <>
      <div className="page--get-started bg-gradient-grey flex flex-col lg:flex-row pt-16 md:pt-xl text-center">
        <div
          className="flex-auto pb-20 pt-half-screen lg:pt-0 w-full lg:w-6/12 flex flex-col justify-end items-center px-6 md:px-16 bg-top bg-cover bg-no-repeat"
          style={{
            backgroundImage: 'url(/images/get-started-convenience-retail.png)',
          }}
        >
          <div className="max-w-md">
            <h2 className="text-5xl font-semibold tracking-tightest mb-3.5 text-paragraph">
              {convenienceRetail?.title}
            </h2>
            <p className="text-xl mb-8 text-dark-grey">
              {convenienceRetail?.body}
            </p>
            <CtaButton
              to="https://go.skupos.com/signup"
              label="Get started"
              inverted
            />
          </div>
        </div>
        <div
          className="flex-auto pb-20 pt-half-screen lg:pt-0 w-full lg:w-6/12 flex flex-col justify-end items-center px-6 md:px-16 bg-top bg-cover bg-no-repeat"
          style={{
            backgroundImage: 'url(/images/get-started-enterprise-brand.png)',
          }}
        >
          <div className="max-w-md">
            <h2 className="text-5xl font-semibold tracking-tightest mb-3.5 text-paragraph">
              {enterpriseBrand?.title}
            </h2>
            <p className="text-xl mb-8 text-dark-grey">
              {enterpriseBrand?.body}
            </p>
            <CtaButton to="/sign-up" label="Get started" inverted />
          </div>
        </div>
      </div>
    </>
  );
};

export const GetStartedPreview = ({ entry }) => {
  return <GetStartedContent {...entry.get('data').toJS()} />;
};

const GetStarted = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description?.description}
        openGraphImage={seo?.openGraphImage?.file.url}
      />
      <GetStartedContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query GetStartedPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/get-started/" }) {
      frontmatter {
        seo {
          title
          description
        }
        convenienceRetail {
          title
          body
        }
        enterpriseBrand {
          title
          body
        }
      }
    }
  }
`;

export default GetStarted;
